import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import SemTabsContent from './components/SemStatistic/SemTabsContent'
import SeoTabsContent from './components/SeoStatistic/SeoTabsContent'
import SemTabsNav from './components/SemStatistic/SemTabsNav'
import { TAB_NAME, SEM_TAB_NAME, FB_TAB_NAME, IReportDetails } from './models/Reports'
import { SEO_TAB_NAME } from './models/Reports'
import { actions, selectors } from './redux/ReportsRedux'
import { useDispatch, useSelector } from 'react-redux'
import LoadingView from '../common/LoadingView/LoadingView'
import SeoTabsNav from './components/SeoStatistic/SeoTabsNav'
import FbTabsNav from './components/FbStatistic/FbTabsNav'
import FbTabsContent from './components/FbStatistic/FbTabsContent'
import ErrorView from '../common/ErrorView/ErrorView'
import BingTabsNav from "./components/BingStatistic/BingTabsNav";
import BingTabsContent from "./components/BingStatistic/BingTabsContent";
import TiktokTabsContent from "./components/TiktokStatistic/TiktokTabsContent";
import TiktokTabsNav from "./components/TiktokStatistic/TiktokTabsNav";
import AllegroTabsNav from "./components/AllegroStatistic/AllegroTabsNav";
import AllegroTabsContent from "./components/AllegroStatistic/AllegroTabsContent";
import { useLocation } from 'react-router-dom';

export type EditeParams = {
  companyId: string
  reportId: string
}
interface ReportViewProps extends RouteComponentProps<EditeParams> {
  showPdf?: boolean
}

const ReportView: React.FC<ReportViewProps> = ({match, showPdf = true}) => {
  const location = useLocation();
  const dispatch = useDispatch()
  const {reportData, reportsError} = useSelector(selectors.getReportsState)
  const [reportId] = useState<string>(match.params.reportId)
  const [companyId] = useState<string>(match.params.companyId)
  const [semTab, setSemTab] = useState<string>(SEM_TAB_NAME.GADS)
  const [fbTab, setFbTab] = useState<string>(FB_TAB_NAME.ANALYSIS)
  const [seoTab, setSeoTab] = useState<string>(SEO_TAB_NAME.GOOGLE_SEARCH_CONSOLE)
  const [bingTab, setBingTab] = useState<string>(TAB_NAME.ANALYSIS)
  const [tiktokTab, setTiktokTab] = useState<string>(TAB_NAME.ANALYSIS)
  const [allegroTab, setAllegroTab] = useState<string>(TAB_NAME.ANALYSIS)
  const [reportDetails, setReportsDetails] = useState<IReportDetails>()
  const [isPdfVisible] = useState<boolean>(showPdf);

  useEffect(() => {
    const splashScreen = document.getElementById('splash-screen');
    const containsIsolated = location.pathname.includes('isolated');
    if(containsIsolated) {
      splashScreen?.classList.add('d-none');
    }

  }, [])
  useEffect(() => {
    setReportsDetails(undefined)
    dispatch(actions.requestReportById(Number(reportId), Number(companyId)))
  }, [reportId, companyId, dispatch])

  useEffect(() => {
    if (reportData) setReportsDetails(reportData)
    console.log(reportData)

    if(reportData && reportData.details && reportData.details.googleAdsConfig){
      setSemTab(SEM_TAB_NAME.GADS)
    }else{
      setSemTab(SEM_TAB_NAME.COMMENT)
    }

    if (reportData && reportData.details && reportData.details.fbCampaign && reportData.details.fbCampaign.data) {
      if (reportData.details.fbCampaign.data.length > 0) {
        setFbTab(FB_TAB_NAME.ANALYSIS);
      } else {
        setFbTab(FB_TAB_NAME.COMMENT);
      }
    }

    if (reportData && reportData.type === 'bing') {
      const bingDataExists = reportData.details && Array.isArray(reportData.details.bingAdsCampaignList) && reportData.details.bingAdsCampaignList.length > 0;
      setBingTab(bingDataExists ? TAB_NAME.ANALYSIS : TAB_NAME.COMMENT);
    }


    if (reportData && reportData.type === 'tiktok') {
      const tiktokDataExists =
        reportData &&
        reportData.type === 'tiktok' &&
        reportData.details &&
        reportData.details.monthly &&
        reportData.details.monthly.length > 0 &&
        reportData.details.top10ads &&
        reportData.details.top10ads.length > 0;

      setTiktokTab(tiktokDataExists ? TAB_NAME.ANALYSIS : TAB_NAME.COMMENT)
    }

    if (reportData && reportData.type === 'allegro') {
      if (!reportData.details || !reportData.details.allegroDaily) {
        setAllegroTab(TAB_NAME.COMMENT)
      }
    }

  }, [reportData])

  switch (reportDetails?.type) {
    case 'fb':
      return (
        <>
          <div className='card card-custom'>
            <div className='card-header bg-white px-8 min-h-50px pt-5'>
              <FbTabsNav tab={fbTab} setTab={setFbTab} showPdf={isPdfVisible}/>
            </div>
            <div className='card-body px-5 py-5 pt-0'>
              <FbTabsContent tab={fbTab} />
            </div>
          </div>
        </>
      )
    case 'sem':
      return (
          <>
            <div className='card card-custom'>
              <div className='card-header bg-white px-8 min-h-50px pt-5'>
                <SemTabsNav tab={semTab} setTab={setSemTab} showPdf={isPdfVisible}/>
              </div>
              <div className='card-body px-5 py-5 pt-0'>
                <SemTabsContent tab={semTab} />
              </div>
            </div>
          </>
      )
    case 'dat':
      return (
          <>
            <div className='card card-custom'>
              <div className='card-header bg-white px-8 min-h-50px pt-5'>
                <SemTabsNav tab={semTab} setTab={setSemTab} showPdf={isPdfVisible}/>
              </div>
              <div className='card-body px-5 py-5 pt-0'>
                <SemTabsContent tab={semTab} />
              </div>
            </div>
          </>
      )
    case 'allegro':
      return (
          <>
            <div className='card card-custom'>
              <div className='card-header bg-white px-8 min-h-50px pt-5'>
                <AllegroTabsNav tab={allegroTab} setTab={setAllegroTab} showPdf={isPdfVisible}/>
              </div>
              <div className='card-body px-5 py-5 pt-0'>
                <AllegroTabsContent tab={allegroTab} />
              </div>
            </div>
          </>)
    case 'seo':
      return (
        <>
          <div className='card card-custom'>
            <div className='card-header bg-white px-8 min-h-50px pt-5'>
              <SeoTabsNav tab={seoTab} setTab={setSeoTab} showPdf={isPdfVisible} />
            </div>
            <div className='card-body px-5 py-5 pt-0'>
              <SeoTabsContent tab={seoTab} />
            </div>
          </div>
        </>
      )
    case 'bing':
      return (
          <>
            <div className='card card-custom'>
              <div className='card-header bg-white px-8 min-h-50px pt-5'>
                <BingTabsNav tab={bingTab} setTab={setBingTab} showPdf={isPdfVisible}/>
              </div>
              <div className='card-body px-5 py-5 pt-0'>
                <BingTabsContent tab={bingTab}/>
              </div>
            </div>
          </>
      )
    case 'tiktok':
      return (
          <>
            <div className='card card-custom'>
              <div className='card-header bg-white px-8 min-h-50px pt-5'>
                <TiktokTabsNav tab={tiktokTab} setTab={setTiktokTab} showPdf={isPdfVisible}/>
              </div>
              <div className='card-body px-5 py-5 pt-0'>
                <TiktokTabsContent tab={tiktokTab}/>
              </div>
            </div>
          </>
      )
    default:
      if (reportsError) return <ErrorView />
      return <LoadingView />
  }
}

export default withRouter(ReportView)
